import React from "react"

import "./footer.css"

import Facebook from "../../images/icons/social-media/facebook.png"
import Instagram from "../../images/icons/social-media/instagram.png"
import Linkedin from "../../images/icons/social-media/linkedin.png"
import Twitter from "../../images/icons/social-media/twitter.png"
import Youtube from "../../images/icons/social-media/youtube.png"

import MarsLogoWhite from "../../images/mars-logo-white.png"
import TextMFooter from "../../images/text-m-footer.png"

import LogoCare from "../../images/brands/logo-care.png"
import LogoMMS from "../../images/brands/logo-mms.png"
import LogoTwix from "../../images/brands/logo-twix.png"
import LogoFreedent from "../../images/brands/logo-freedent.png"
import LogoRoyalCanin from "../../images/brands/logo-royal-canin.png"
import LogoPedigree from "../../images/brands/logo-pedigree.png"
import LogoWhiskas from "../../images/brands/logo-whiskas.png"
import LogoBensOriginal from "../../images/brands/logo-bens-original.png"
import LogoSuziwan from "../../images/brands/logo-suziwan.png"

const Footer = () => {
  return (
    <>
      <footer className="main-footer fr">
        <div className="container">
          <div className="row">
            <div className="col-md-9 d-none d-md-block">
              <div className="title-footer">
                <img src={TextMFooter} alt="" />
                <h4 className="text-mars-green">
                  Le monde que nous voulons demain dépend
                  <span className="text-mars-yellow d-md-block">
                    de la façon dont nous agissons aujourd'hui.
                  </span>
                </h4>
              </div>
            </div>
            <div className="col-md-3">
              <figure className="mb-0 mars-logo-white">
                <img className="img-fluid" src={MarsLogoWhite} alt="" />
              </figure>
              <p className="text-white">En partenariat avec</p>
              <figure className="mb-0 logo-care-footer d-md-none text-center">
                <img src={LogoCare} alt="" />
              </figure>
            </div>
          </div>
        </div>
      </footer>
      <div className="mars-footer-container fr">
        <div className="container">
          <div className="row pb-md-4">
            <div className="col-md-9">
              <div className="d-md-flex align-items-md-center">
                <div className="mars-footer-terms pt-4 pb-3 py-md-0 m-md-0 me-md-3">
                  <div>
                    <a
                      href="https://fra.mars.com/nous-contacter"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <h4 className="text-white mb-md-0">Nous contacter</h4>
                    </a>
                  </div>
                  <div>
                    <a
                      href="https://careers.mars.com/fr/fr"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <h4 className="text-white mb-md-0">Carrières</h4>
                    </a>
                  </div>
                </div>
                <div className="social-media-links-footer mb-4 mb-md-0 d-md-inline-block">
                  <div className="d-md-flex align-items-md-center">
                    <p className="text-white text-center text-md-left mb-md-0 me-md-3">
                      Retrouvez-nous:
                    </p>
                    <div className="d-flex justify-content-center justify-content-md-start align-items-center">
                      <a
                        href="https://www.facebook.com/Mars"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          className="facebook mx-4 me-md-3 mx-lg-4 ms-md-0"
                          src={Facebook}
                          alt=""
                        />
                      </a>
                      <a
                        href="https://www.instagram.com/marsglobal"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          className="instagram me-4 me-md-3 mx-lg-4"
                          src={Instagram}
                          alt=""
                        />
                      </a>
                      <a
                        href="https://twitter.com/Marsglobal"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          className="twitter me-4 me-md-3 mx-lg-4"
                          src={Twitter}
                          alt=""
                        />
                      </a>
                      <a
                        href="https://www.linkedin.com/company/mars"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          className="linkedin me-4 me-md-3 mx-lg-4"
                          src={Linkedin}
                          alt=""
                        />
                      </a>
                      <a
                        href="https://www.youtube.com/user/Mars"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          className="youtube me-4 mx-lg-4 me-md-0"
                          src={Youtube}
                          alt=""
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3 text-md-right">
              <img
                className="d-none d-md-inline-block logo-care-footer"
                src={LogoCare}
                alt=""
              />
            </div>
          </div>

          <div className="d-flex flex-column justify-content-center mb-3 flex-md-row justify-content-md-start flex-md-wrap">
            <a
              href="https://www.mars.com/privacy-policy-france"
              target="_blank"
              rel="noopener noreferrer"
              className="text-white my-2 me-md-5"
            >
              Politique de confidentialité
            </a>
            <a
              href="https://www.mars.com/cookies-france"
              target="_blank"
              rel="noopener noreferrer"
              className="text-white my-2 me-md-5"
            >
              Information sur les cookies
            </a>
            <a
              href="https://www.mars.com/legal-france"
              target="_blank"
              rel="noopener noreferrer"
              className="text-white my-2 me-md-5"
            >
              Mentions légales
            </a>
            <a
              href="fra.mars.com/sitemap"
              target="_blank"
              rel="noopener noreferrer"
              className="text-white my-2 me-md-5"
            >
              Plan du site
            </a>
            <a
              href="https://www.martiansreunited.com/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-white my-2 me-md-5"
            >
              Mars Alumni
            </a>
            <a
              href="https://fra.mars.com/ca-supply-chain-act"
              target="_blank"
              rel="noopener noreferrer"
              className="text-white my-2 me-md-5"
            >
              Loi californienne sur les chaînes d'approvisionnement
            </a>
            <a
              href="https://fra.mars.com/loi-sur-lesclavage-moderne"
              target="_blank"
              rel="noopener noreferrer"
              className="text-white my-2 me-md-5"
            >
              Loi sur l'esclavage moderne
            </a>
            <a
              href="https://fra.mars.com/accessibility"
              target="_blank"
              rel="noopener noreferrer"
              className="text-white my-2 me-md-5"
            >
              Accessibilité
            </a>
            <a
              href="https://fra.mars.com/Nos-sites"
              target="_blank"
              rel="noopener noreferrer"
              className="text-white my-2 me-md-5"
            >
              Sites
            </a>
          </div>
          <div className="mars-copy-right py-4 pb-5">
            <p className="text-white">
              &copy; 2020 Mars, Incorporated et ses filiales. Tous droits
              réservés
            </p>
          </div>
        </div>
      </div>

      <div className="co-brand fr bg-white p-4 position-relative">
        <div className="arrow-up">ArrowUp</div>
        <div className="d-md-flex align-items-md-center justify-content-md-center position-relative co-brand-hldr">
          <h5 className="mb-md-0 me-md-3 text-mars-blue">
            Font fièrement partie du portefeuille de Mars
          </h5>
          <div className="d-flex flex-wrap flex-xl-nowrap my-2 my-md-0">
            <figure className="mb-0">
              <img className="img-fluid" src={LogoMMS} alt="" />
            </figure>
            <figure className="mb-0">
              <img className="img-fluid" src={LogoTwix} alt="" />
            </figure>
            <figure className="mb-0">
              <img className="img-fluid" src={LogoFreedent} alt="" />
            </figure>
            <figure className="mb-0">
              <img className="img-fluid" src={LogoRoyalCanin} alt="" />
            </figure>
            <figure className="mb-0">
              <img className="img-fluid" src={LogoPedigree} alt="" />
            </figure>
            <figure className="mb-0">
              <img className="img-fluid" src={LogoWhiskas} alt="" />
            </figure>
            <figure className="mb-0">
              <img className="img-fluid" src={LogoBensOriginal} alt="" />
            </figure>
            <figure className="mb-0">
              <img className="img-fluid" src={LogoSuziwan} alt="" />
            </figure>
          </div>
        </div>
      </div>
    </>
  )
}

export default Footer
