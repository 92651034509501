import { Link } from "gatsby"
import React, { useState } from "react"
import { Modal, Button } from "react-bootstrap"
import { useForm } from "react-hook-form"
// import axios from "axios"
import { CopyToClipboard } from "react-copy-to-clipboard"
import {
  FacebookShareButton,
  // FacebookMessengerShareButton,
  TwitterShareButton,
} from "react-share"

import "./thankyou-socialmedia.css"

const ThankyouSocialmedia = props => {
  const [showExist, setShowExist] = useState(false)
  const [formError, setFormError] = useState(false)
  const [registered, setRegistered] = useState(false)
  const { register, handleSubmit, errors, reset } = useForm()

  const [show, setShow] = useState(false)
  const handleClose = () => {
    setShow(false)
    setRegistered(false)
    setShowExist(false)
  }
  const handleShow = () => setShow(true)

  const onSubmit = data => {
    // const formData = {
    //   Language_Code: "FR",
    //   Email: data.email,
    //   MpsOptin: data.consent,
    // }

    // Stage
    // const oTdata = {
    //   identifier: data.email,
    //   test: true,
    //   requestInformation:
    //     "eyJhbGciOiJSUzUxMiJ9.eyJvdEp3dFZlcnNpb24iOjEsInByb2Nlc3NJZCI6IjRiOGYwNzA2LWVhNTctNDM5OC05N2IxLWQwNWFmMDM2ZGZlNiIsInByb2Nlc3NWZXJzaW9uIjoyLCJpYXQiOiIyMDIwLTEyLTE3VDEzOjAxOjU1LjY3IiwibW9jIjoiQVBJIiwic3ViIjoiRW1haWwiLCJpc3MiOm51bGwsInRlbmFudElkIjoiMjA4MmU1MGYtN2E5ZS00MjJiLTliYTAtYWY1YjVlZWRhZDI5IiwiZGVzY3JpcHRpb24iOiJGdWxsUG90ZW50aWFsX0JlaGVhcmRfR2xvYmFsX0Zvcm1fVGVzdCIsImNvbnNlbnRUeXBlIjoiQ09ORElUSU9OQUxUUklHR0VSIiwiZG91YmxlT3B0SW4iOmZhbHNlLCJyZWNvbmZpcm1BY3RpdmVQdXJwb3NlIjpmYWxzZSwiYXV0aGVudGljYXRpb25SZXF1aXJlZCI6ZmFsc2UsInBvbGljeV91cmkiOm51bGwsImFsbG93Tm90R2l2ZW5Db25zZW50cyI6ZmFsc2UsInB1cnBvc2VzIjpbeyJpZCI6IjZjMDNmYjJhLTM3OTgtNDJjZi1hMmEzLTkxZDkyNWQ4Y2Y0OSIsInZlcnNpb24iOjIsInRvcGljcyI6W10sImN1c3RvbVByZWZlcmVuY2VzIjpbXX1dLCJub3RpY2VzIjpbXSwiZHNEYXRhRWxlbWVudHMiOltdfQ.QNwbrtv1gwEwnHKaRjrsygPZ4xibFf6qCEs0Be8FsH49XHYyKL7sxvFIaVmxhS4juU4v8AyKePMzQW2GVEY6pfZUta28Z57CZeKA1OsAFCUQzEmlE_WEprKKg6Xc6MCY-qd16WftGRoean8LLTABxjHEhYTuZ4iboCym5z2nzXOSv4jtBtgxxUxtu8VgGcW4JXI0mhOGJADOGPLrjh0dFE22xZm-EUI6zajAKFJF9coELEanWCqW3Meb2TKevJNsNtHfD8vMovUJ-Nnm2yl2y6SpRRyTZ3L5g3Wv1JmWaqtvQx9QM_89yX-DJseTpfeAugK_re_cY4TiT2N_JkO4LH3V75AAzwiQzcORE0Q2dHRZbKA5RkKyESc5Htpf3fHKgn8yF4VrNyI65l8Ob1DbcqLDdBDc2rnzgmMjyjRf2jKe6P5_lopdgxHKWcyoM_O3Xxga9CilQzzHCTFYR_GYn6dBP1VqRXz52ZgGCcXIyL6lF1uGxse4gF96drmRFzEbGImR5TbfEnNx7d2XZiPTP9KDBJmINnDouAfhpxL0cLxfRdslRlbThjcoZS65rZvmojZf5pV-JNgAmROKvAD3HHGT25g-_JyxditXhIgIAZoBOhA6BaWijvtwKPp2CXQ_At0nRktTEGRM2bY0mq26yattOkX0UwdbqR5sqlzrHA8",
    //   purposes: [
    //     {
    //       Id: "6c03fb2a-3798-42cf-a2a3-91d925d8cf49",
    //     },
    //   ],
    // }

    // Production
    // const oTdata = {
    //   identifier: data.email,
    //   test: false,
    //   requestInformation:
    //     "eyJhbGciOiJSUzUxMiJ9.eyJvdEp3dFZlcnNpb24iOjEsInByb2Nlc3NJZCI6ImQ1MjZhOGI1LThjOGUtNDI0Mi1hMjY1LTEyMjFjNTNhNjQwOSIsInByb2Nlc3NWZXJzaW9uIjoxLCJpYXQiOiIyMDIwLTEyLTE3VDEzOjE0OjA1LjMzMyIsIm1vYyI6IkFQSSIsInN1YiI6IkVtYWlsIiwiaXNzIjpudWxsLCJ0ZW5hbnRJZCI6IjVmMjc1OWZmLTllZmUtNDEyYy1hM2JlLWYxMzA5MmQyYzk0NSIsImRlc2NyaXB0aW9uIjoiRnVsbFBvdGVudGlhbF9CZWhlYXJkX0dsb2JhbF9Gb3JtIiwiY29uc2VudFR5cGUiOiJDT05ESVRJT05BTFRSSUdHRVIiLCJkb3VibGVPcHRJbiI6ZmFsc2UsInJlY29uZmlybUFjdGl2ZVB1cnBvc2UiOmZhbHNlLCJhdXRoZW50aWNhdGlvblJlcXVpcmVkIjpmYWxzZSwicG9saWN5X3VyaSI6bnVsbCwiYWxsb3dOb3RHaXZlbkNvbnNlbnRzIjpmYWxzZSwicHVycG9zZXMiOlt7ImlkIjoiMTI4Njk5ZDEtNGMwNC00ZDMyLWIxNzktOTUyMjNmYzcyMjE0IiwidmVyc2lvbiI6MSwidG9waWNzIjpbXSwiY3VzdG9tUHJlZmVyZW5jZXMiOltdfV0sIm5vdGljZXMiOltdLCJkc0RhdGFFbGVtZW50cyI6W119.jjWXIKvP38dgRPHY30CcKyJ-kTfxZIO1dlsQLy4iT8Y9kDamGM-uehelwHbJzjDkjHObKJWRpoaCz3joN36TU0YheO5NFpjykTkw07eNFnDYTLxhDudC6n4sH69umvIg-NdklW8Y8_tHTrM_1zuYXjSgo9zOyFldo9A3F-G05QhPviKys4WH1oT9csn2_cqwiURa8RKXaiTblLGYZNw8bUlR0WsAPRIPnQ1KMEiYdEOmJHdk1HXOAMqv5BuYa9E3YCiOjuc8XUN4huwEE5FpcUS8MrwTWq5dY83uRufzCnCJUXVC1vRZXTJOJO8hwP9oSu1u_uKWeueHd_VOh5qmaFB0MHrcjNGR4LSjBsa7puzCqVwW7l5j_KqE8rh8we9vmJFPJocjWNf8Y0za_SZ9DjJEkuULVo4Lar94OmIIEOcJINws6ueY17csucpj6AUbGcgOBe5aSAfCIW4_2RZNMsZfKGwp5HlZVX8exic1B4seHpQvZdFrpgBt-_9QFPn9V-42s3BKOb4aPrC9uQPUK_pEVH_WjO48uPye3UI-oCD9QZswZ8vlcA3NksV-rVazyg2NO0r_NtOrik5-ott9rMgzqSOdiBDi3osgJA-5v0q1l9cqejMoHcMWiqHndtEjF_eOr_MPQhX0s6iWfEfN8dvhA30MvKgehhoHYn6yhNg",
    //   purposes: [
    //     {
    //       Id: "128699d1-4c04-4d32-b179-95223fc72214",
    //     },
    //   ],
    // }

    // const oTdataString = JSON.stringify(oTdata)
    // //console.log(oTdataString)

    // // Post Headers
    // const postHeaders = {
    //   headers: {
    //     "Content-Type": "application/json; charset=utf-8",
    //     "Access-Control-Allow-Origin": "*",
    //     "Access-Control-Allow-Headers":
    //       "Origin, X-Requested-With, Content-Type, Accept",
    //   },
    // }
    // // One Trust Post Headers
    // const postOTHeaders = {
    //   headers: {
    //     // "content-type": "application/x-www-form-urlencoded",
    //     "Content-Type": "text/plain",
    //     Cookie: "__cfduid=decbdfda79a761e7c816e682795cd3a281610358724",
    //   },
    // }
    // axios
    //   .all([
    //     axios.post(
    //       // Stage
    //       // "https://stage.api.beheard.mars.com/SaveUser",

    //       // Production
    //       "https://api.beheard.mars.com/SaveUser",
    //       formData,
    //       postHeaders
    //     ),
    //     axios.post(
    //       // Stage
    //       // "https://privacyportaluatde.onetrust.com/request/v1/consentreceipts",

    //       // Production
    //       "https://privacyportal-eu.onetrust.com/request/v1/consentreceipts",
    //       oTdataString,
    //       postOTHeaders
    //     ),
    //   ])
    //   .then(function (response) {
    //     // console.log(response[0])
    //     // console.log(response[1])
    //     if (response[0].data.status === "1") {
          setFormError(false)
          setShowExist(true)
      //   } else if (response[0].data.status === "0") {
      //     reset(formData)
      //     setShowExist(false)
      //     setRegistered(true)
      //   }
      // })
      // .catch(function (error) {
      //   setFormError(true)
      //   console.log(error)
      // })
  }

  return (
    <>
      <div className="content content-social-media">
        <div className="left">
          <p className="para para-social-media-one">
            Aidez-nous à développer la communauté de femmes qui donnent de la
            voix en partageant avec vos amis sur les réseaux sociaux.
          </p>
          <p className="para para-social-media-two">
            Abonnez-vous et restez au courant de nos progrès sur le mouvement{" "}
            <span className="font-weight-bold">#HereToBeHeard</span>
          </p>
        </div>
        <div className="right">
          <div className="social-media-links">
            {/* <button
            className="btn btn-white"
            data-toggle="modal"
            data-target="#myModal"
          >
            Get campaign updates
          </button> */}
            <Button variant="white" onClick={handleShow}>
              Obtenir des mises à jour sur les campagnes
            </Button>
            <div className="btn btn-white">
              <FacebookShareButton
                url={`${props.origin}/fr/index.html`}
                quote={`Je viens de donner de la voix pour aider à construire un avenir meilleur pour les femmes. Donnez de la vôtre sur https://beheard.mars.com/fr pour montrer que vous êtes #HereToBeHeard`}
              >
                Partager sur Facebook
              </FacebookShareButton>
            </div>

            {/* <div className="btn btn-white">
            <FacebookMessengerShareButton
              url={props.origin}
              appId="416880696318535"
            >
              Share on Messenger
            </FacebookMessengerShareButton>
          </div> */}
            <div className="btn btn-white">
              <TwitterShareButton
                title={`Je viens de donner de la voix pour aider à construire un avenir meilleur pour les femmes. Donnez de la vôtre sur https://beheard.mars.com/fr pour montrer que vous êtes #HereToBeHeard`}
                url={`${props.origin}/fr/index.html`}
              >
                Partager sur Twitter
              </TwitterShareButton>
            </div>
            <CopyToClipboard text={`${props.origin}/fr/index.html`}>
              <div className="btn btn-white">Copier le lien</div>
            </CopyToClipboard>
          </div>
          {/* <div
          className="btn btn-mars-blue btn-back-home"
          onClick={() => {
            props.setSection("landing")
          }}
        >
          Back to Home
        </div> */}
          <Link
            to="/fr"
            className="btn btn-mars-blue btn-back-home"
            onClick={() => {
              props.setSection("record-your-voice")
            }}
          >
            Retour
          </Link>
          <div
            className="transition-control"
            onTransitionEnd={() => {
              props.setSection("thankyou social-media enable-scroll")
            }}
          >
            Transition Control Element
          </div>
        </div>
      </div>

      <Modal className="get-campaign-modal fr" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-flex flex-direction-column align-items-center">
          {!registered ? (
            <div>
              <h3>Abonnez-vous aux dernières infos sur la campagne</h3>
              <p>
                Enregistrez-vous pour recevoir les actualités de
                l’équipe Mars Brand sur la campagne, ainsi que les dernières
                études. Vos données personnelles sont conservées séparément de
                vos réponses dans l’enquête.
              </p>

              <form onSubmit={handleSubmit(onSubmit)}>
                {formError ? (
                  <span className="form-error">Something Went Wrong!</span>
                ) : null}

                <div className="form-group">
                  <label htmlFor="email" className="text-field-label">
                    Email Address
                  </label>
                  <input
                    className="form-control email-field"
                    name="email"
                    id="email"
                    placeholder="Enter email"
                    aria-labelledby="email"
                    ref={register({
                      required: "Email ID is Required",
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                        message: "Invalid Email Address",
                      },
                    })}
                  />
                  {errors.email && (
                    <span className="error">{errors.email.message}</span>
                  )}
                  {showExist ? (
                    <span className="error">User Already Exists!</span>
                  ) : null}
                </div>

                <div className="form-group form-check">
                  <label className="form-check-label">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="consent"
                      id="consent"
                      aria-labelledby="consent"
                      ref={register({ required: true })}
                    />{" "}
                    Oui, j’aimerais recevoir des communications de l’équipe
                    Brand pour rester informé du programme Full Potential.
                  </label>
                  {errors.consent && (
                    <span className="error">Please Agree to Consent</span>
                  )}
                </div>
                <button type="submit" className="btn btn-mars-green rounded-0">
                  Register for updates
                </button>
              </form>
            </div>
          ) : (
            <h3 className="d-block text-center w-100">
              Merci de vous être abonné(e) aux actualités du programme.
            </h3>
          )}
        </Modal.Body>
      </Modal>
    </>
  )
}

export default ThankyouSocialmedia
