import React from "react"

import "./record-your-voice.css"

const RecordYourVoiceContent = props => {
  return (
    <>
      <div className="content content-record-your-voice">
        <h4 className="title title-record-your-voice">
          Participez à notre étude mondiale en répondant à cette question:
        </h4>
        <h1 className="title title-record-your-voice">
          <span className="one">Qu’est-ce qui </span>
          <span className="two">doit changer pour </span>
          <span className="three">que davantage </span>
          <span className="four d-lg-none">de femmes réalisent </span>
          <span className="five d-lg-none">leur plein potentiel? </span>
          <span className="four d-none d-lg-block">de femmes </span>
          <span className="five d-none d-lg-block">réalisent leur plein </span>
          <span className="six d-none d-lg-block">potentiel? </span>
          {/* {titleData.map(title => (
            <span key={title.id} className={title.className}>
              {title.textData}
            </span>
          ))} */}
        </h1>
        <a
          href="https://survey.phonic.ai/5fae0acd31623c02be2ca836?lang=fr"
          className="btn btn-add-voice btn-record-your-voice"
          id="survey-fr"
          target="_blank"
          rel="noopener noreferrer"
        >
          Exprimez-vous dès maintenant
        </a>
        <div
          className="transition-control"
          onTransitionEnd={() => props.setScrollControl(true)}
        >
          Transition Control Element
        </div>
      </div>
    </>
  )
}

export default RecordYourVoiceContent
