import React from "react"

import "./full-potential.css"

const FullPotentialContent = props => {
  return (
    <>
      <div className="content content-full-potential">
        <h2 className="title title-full-potential">
          <span className="one">La plateforme</span>
          <span className="two">Full Potential</span>
          <span className="three">de Mars</span>
        </h2>
        <h4 className="title title-full-potential">
          Dans le monde que nous voulons pour demain, la société sera inclusive
          et toutes les femmes atteindront leur plein potentiel.
        </h4>
        <p className="para para-full-potential">
          Nous savons que créer des opportunités pour les femmes contribue à un
          monde meilleur pour tous. C'est la raison pour laquelle nous mettons
          en oeuvre des changements importants sur nos lieux de travail, dans
          nos communautés d'approvisionnement et dans nos publicités, afin de
          garantir que chacun ait la possibilité de réaliser son plein
          potentiel.
        </p>
        <a
          href="https://www.mars.com/sustainability-plan/thriving-people/unlocking-opportunities-for-women"
          target="_blank"
          rel="noopener noreferrer"
          className="btn btn-mars-blue rounded-0 btn-full-potential"
          id="learn-more-fr"
        >
          En savoir plus
        </a>
        <div
          className="transition-control"
          onTransitionEnd={() => props.setScrollControl(true)}
        >
          Transition Control Element
        </div>
      </div>
    </>
  )
}

export default FullPotentialContent
